html, body {
  margin: 0;
  padding:0;
  font-size:100%;
}
html {
  height:100%;
}
body {
  background-color: #f1f1f1;
  background-image:url('/images/bg_main.png');
  background-repeat:repeat-x;
  margin: 0;
  padding:0;
  font-family: 'Open Sans', sans-serif;
  font-size:100%;
  height: 100%;
  min-height:100%;
  overflow-x:hidden;
}
p, ul, li, ol {
  color:$fontColor;
  line-height:1.8em;
  font-family: 'Open Sans', sans-serif;
  font-weight:300;
}
p, ul {
  margin:0 0 25px;
}
a {
  color:$blue;
  &:hover,
  &:focus {
    color:$blue;
    outline:0;
  }
}
.wrapper {
  background:white;
  max-width:900px;
  min-height:100%;
  margin:0 auto;
  padding:0;
  width:100%;
  position:relative;
  box-shadow: 4px 0 15px -4px rgba(0, 0, 0, 0.3), -4px 0 8px -4px rgba(0, 0, 0, 0.3);
  .container {
    max-width:900px;
  }
}
.logo {
  max-width: 145px;
  margin-top: 15px;
  display: block;
  float: left;
  position: relative;
  margin-left: 10px;
  img {
    max-width:100%;
    display:block;
  }
}
.contact {
  float:right;
  position:relative;
  margin-top:30px;
  color:$blue;
  ul {
    margin:0;
    padding:0;
    list-style:none;
    li {
      float:left;
      margin-right:60px;
      line-height:1.8em;
      color:$blue;
      i {
        min-width:25px;
        text-align:center;
        &.fa-mobile {
          font-size:20px;
        }
      }
      &:last-child {
        margin-right:25px;
      }
    }
  }
}
.materiaal_item {
  float:left;
  width:100%;
  margin:0 0 30px 0;
  h2 {
    font-size:1.4em;
    color:$blue;
    margin-bottom:3px;
  }
  p {
    margin:0;
    padding:0;
  }
  .image {
    padding:0 15px;
  }
}

.table {
  tbody {
    tr {
      td {
        border:0;
        padding:5px 0;
      }
    }
  }

}