@media only screen and (max-width: 767px) {
  .navbar-default {
    background: $blue;
    border: 0;
    border-radius: 0;
    margin-top: 0px;
    position: fixed;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    z-index: 1001;
    .navbar-toggle {
      background: white;
      .icon-bar {
        background-color: $blue;
      }
    }
    #navbar {
      .navbar-nav {
        margin: 0 -15px;
        li {
          border-bottom: 1px solid white;
          a {
            color: white;
          }
          &.active {
            a {
              background: white;
              color: $blue;
            }
          }
        }
      }
    }
  }
  .logo {
    margin: 75px auto 0 auto;
    max-width: 100%;
    width: 100%;
    text-align: center;
    img {
      max-width: 125px;
      display: block;
      margin: 0 auto;
    }
  }
  .contact {
    margin: 25px 0;
    text-align: left;
    background: #f9f9f9;
    padding: 15px;
    width: 100%;
    ul {
      li {
        margin-right: 0;
      }
    }
  }
  .adres {
    margin-bottom:15px;
    margin-right:15px;
  }
  .materiaal_item {
    border-bottom:1px solid #f9f9f9;
    padding-bottom:30px;
    margin-bottom:30px;
    h2 {
      margin-top:0;
    }
    .image {
      margin-top:15px;
    }
  }

}