@media only screen and (min-width : 768px) {
  .navbar-default {
    background:$blue;
    border:1px solid $blue;
    margin-top:13px;
    border-radius:0;

    #navbar {
      border:0;
      padding-left:0;
      .navbar-nav {
        li {
          border-right:1px solid white;
          a {
            color:white;
            text-transform:uppercase;
            font-weight:700;
            letter-spacing:2px;
            &:hover,
            &:focus {
              background:white;
              color:$blue;
            }
          }
          &.active {
            a {
              background:white;
              color:$blue;
            }
          }
        }
      }
    }
  }
}